/**
* @summary Functionality for creating and display the assistant as a widget
*/

/*BASE CLASS*/
class ExternalAssistant {
    id: string;
    mantaHost: string;
    stylePath: string;
    iconPath: string;
    framePath: string;
    scriptFilePath: string;
    scriptFuncPath: string;

    constructor(id: string, isDev: boolean) {
        this.id = id;
        //TODO: Will need to update url for prod
        this.mantaHost = "https://barnaclestudios.com";
        if (isDev) {
            this.mantaHost = "https://localhost:7034";
        }
        this.stylePath = this.mantaHost + "/css/manta/external/external-assistant.css";
        this.iconPath = this.mantaHost + "/vendor/boxicons/css/boxicons.min.css";
        this.framePath = this.mantaHost + "/chat/ea/frame/" + this.id;
        this.scriptFilePath = this.mantaHost + "/js/scripts/site/chat/chatframe.js";
        this.scriptFuncPath = this.mantaHost + "/js/scripts/site/chat/chatframe.js";
    }

    init() {
        const style = this.fetchStyles(this.stylePath);
        const icon = this.fetchStyles(this.iconPath);

        document.head.appendChild(style);
        document.head.appendChild(icon);

        const mantaLoader = new MantaLoader("#4c82f7");
        document.body.appendChild(mantaLoader.loaderObject);

        this.fetchBuildFrame();
    }

    fetchBuildFrame() {
        let self = this;

        let xmlhttp = new XMLHttpRequest();
        let frame = document.createElement("div");
        frame.id = this.id;
        frame.className = "manta-ea-frame-wrapper";

        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState == XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                if (xmlhttp.status == 200) {
                    let loader = document.getElementById("manta-frame-loader");
                    loader?.remove();

                    frame.innerHTML = xmlhttp.responseText;
                    document.body.appendChild(frame);
                    let parentElement = document.getElementById("chat-frame");

                    let chatUrl = self.mantaHost + "/chat/ea/frame/" + (parentElement?.getAttribute("data-bot-uid") ?? "") + "/" + (parentElement?.getAttribute("data-external-bot-uid") ?? "") + "/" + (parentElement?.getAttribute("data-conversation-uid") ?? "");
                    let ratingUrl = self.mantaHost + "/chat/ratelog";

                    let e = new ExternalAssistantChat("chat-frame", chatUrl, ratingUrl);
                }
                else if (xmlhttp.status == 400) {
                    console.log("400");
                }
                else {
                    console.log(xmlhttp.status);
                }
            }
        };

        xmlhttp.open("GET", self.framePath, true);
        xmlhttp.send();
    }

    fetchStyles(url: string) {
        let style = document.createElement("link");
        style.rel = "stylesheet";
        style.media = "screen";
        style.href = url;
        return style;
    }

    fetchChatScript() {
        let chatScript = document.createElement("script");
        chatScript.type = "module";
        chatScript.src = this.scriptFilePath;
        return chatScript;
    }

    buildLoader() {
        let div = document.createElement("div");
        div.id = "manta-frame-loader";
        div.className = "manta-frame-loader";

        let inner = document.createElement("div");
        inner.className = "manta-frame-loader-inner";

        let spinner = document.createElement("span");
        spinner.classList.add("spinner-grow");
        spinner.classList.add("text-info");

        inner.appendChild(spinner);
        div.appendChild(inner);
        return div;
    }
}
/*ACTUAL CHAT WIDGET*/
class ExternalAssistantChat {
    parentId: string;
    chatUrl: string;
    ratingUrl: string;
    parent: HTMLElement | null;
    chatFrame: HTMLDivElement | null;
    chatSendBtn: HTMLButtonElement | null;
    chatHistory: HTMLDivElement | null;
    chatHistoryInner: HTMLDivElement | null;
    suggestedPrompts: HTMLDivElement | null;
    promptLeftBtn: HTMLDivElement | null;
    promptRightBtn: HTMLDivElement | null;
    chatPrompt: HTMLInputElement | null;
    chatForm: HTMLFormElement | undefined;
    chatClose: HTMLElement | null;
    bubble: HTMLDivElement | null;

    constructor(parentId: string, chatUrl: string, ratingUrl: string) {
        this.parentId = parentId;
        this.chatUrl = chatUrl;
        this.ratingUrl = ratingUrl;

        this.parent = document.getElementById(this.parentId);
        this.chatSendBtn = <HTMLButtonElement>document.getElementById("chat-send");
        this.chatFrame = <HTMLDivElement>document.getElementById("chat-frame");
        this.chatHistory = <HTMLDivElement>document.getElementById("chat-history");
        this.chatHistoryInner = <HTMLDivElement>document.getElementById("chat-history-inner");
        this.suggestedPrompts = <HTMLDivElement>document.getElementById("chat-suggested-prompts");
        this.promptLeftBtn = <HTMLDivElement>document.getElementById("chat-suggested-prompts-btn-l");
        this.promptRightBtn = <HTMLDivElement>document.getElementById("chat-suggested-prompts-btn-r");
        this.chatPrompt = <HTMLInputElement>document.getElementById("chat-prompt");
        this.chatForm = <HTMLFormElement>document.getElementById("chat-form");
        this.chatClose = <HTMLElement>document.getElementById("manta-chat-close");
        this.bubble = <HTMLDivElement>document.getElementById("manta-frame-bubble");

        this.init();
    }

    init() {
        this.bindEvents();
        //this.parent?.classList.add("open");
    }

    bindEvents() {
        this.chatForm?.addEventListener("submit", (e) => {
            e.preventDefault();
        });

        this.chatSendBtn?.addEventListener("click", (e) => {
            this.submitChat();
        });

        this.chatPrompt?.addEventListener("keypress", (e) => {
            if (e.keyCode == 13 || e.which == 13) {
                this.submitChat();
            }
        });

        this.chatClose?.addEventListener("click", (e) => {
            this.toggleOpenClose();
        });

        this.bubble?.addEventListener("click", (e) => {
            this.toggleOpenClose();
        });

        this.promptLeftBtn?.addEventListener("click", (e) => {
            this.scrollSuggestionsLeft();
        });

        this.promptRightBtn?.addEventListener("click", (e) => {
            this.scrollSuggestionsRight();
        });
    }

    toggleOpenClose() {
        if (this.chatFrame?.classList.contains("open")) {
            this.parent?.classList.add("manta-ea-frame-clear");
            this.chatFrame?.classList.remove("open");
            this.bubble?.classList.add("open");
        }
        else {
            this.parent?.classList.remove("manta-ea-frame-clear");
            this.chatFrame?.classList.add("open");
            this.bubble?.classList.remove("open");
        }
    }

    submitChat() {
        let self = this;

        if (self.chatPrompt?.value != "" && self.chatPrompt != null) {

            //STP: need to sanitize the input
            self.addUserItemToChat(self.chatPrompt?.value);
            self.toggleSubmitLoading(true);
            if (self.suggestedPrompts != null) {
                self.suggestedPrompts.innerHTML = "";
            }
            self.promptLeftBtn?.classList.add("manta-d-none");
            self.promptRightBtn?.classList.add("manta-d-none");

            setTimeout(function () {
                self.toggleTyping(true);
                if (self.chatHistory != null) {
                    self.chatHistory.scrollTop = self.chatHistory.scrollHeight;
                }

                let xmlhttp = new XMLHttpRequest();

                xmlhttp.onreadystatechange = () => {
                    if (xmlhttp.readyState == XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                        if (xmlhttp.status == 200) {
                            let data = JSON.parse(xmlhttp.response);
                            
                            self.toggleTyping(false);
                            self.addResponseItemToChat(data.message, data.allowRating, data.logUid);

                            if (data.suggestedQuestions.length > 0) {
                                if (self.suggestedPrompts != null) {
                                    self.suggestedPrompts.style.overflowX = "scroll";
                                }

                                for (let i = 0; i < data.suggestedQuestions.length; ++i) {
                                    
                                    if (data.suggestedQuestions[i] != "") {
                                        self.addSuggestedPrompt(data.suggestedQuestions[i]);
                                    }
                                }

                                //self.wireSuggestionScrolling();

                                self.promptLeftBtn?.classList.remove("manta-d-none");
                                self.promptRightBtn?.classList.remove("manta-d-none");
                            }
                            else {
                                if (self.suggestedPrompts != null) {
                                    self.suggestedPrompts.style.overflowX = "auto";
                                }
                                self.promptLeftBtn?.classList.add("manta-d-none");
                                self.promptRightBtn?.classList.add("manta-d-none");
                            }

                            self.toggleSubmitLoading(false);
                            if (self.chatHistory != null) {
                                self.chatHistory.scrollTop = self.chatHistory.scrollHeight;
                            }
                        }
                        else if (xmlhttp.status == 400) {
                            console.log("400");
                        }
                        else {
                            console.log(xmlhttp.status);
                        }
                    }
                };

                xmlhttp.open("POST", self.chatUrl, true);
                xmlhttp.send(new FormData(self.chatForm));
            }, 500);
        }
        return false;
    }

    submitLogRating(posEl: HTMLElement, negEl: HTMLElement, logUid: string, rating: number) {
        let self = this;

        let xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState == XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                if (xmlhttp.status == 200) {
                    switch (rating) {
                        case 0:
                            if (negEl.classList.contains("manta-bg-danger")) {
                                posEl.classList.remove("manta-bg-primary");
                                posEl.classList.remove("manta-text-white");
                                negEl.classList.remove("manta-bg-danger");
                                negEl.classList.remove("manta-text-white");
                            }
                            else {
                                posEl.classList.remove("manta-bg-primary");
                                posEl.classList.remove("manta-text-white");
                                negEl.classList.add("manta-bg-danger");
                                negEl.classList.add("manta-text-white");
                            }
                            break;
                        case 1:
                            if (posEl.classList.contains("manta-bg-primary")) {
                                posEl.classList.remove("manta-bg-primary");
                                posEl.classList.remove("manta-text-white");
                                negEl.classList.remove("manta-bg-danger");
                                negEl.classList.remove("manta-text-white");
                            }
                            else {
                                posEl.classList.add("manta-bg-primary");
                                posEl.classList.add("manta-text-white");
                                negEl.classList.remove("manta-bg-danger");
                                negEl.classList.remove("manta-text-white");
                            }
                            break;
                    }
                }
                else if (xmlhttp.status == 400) {
                    console.log("400");
                }
                else {
                    console.log(xmlhttp.status);
                }
            }
        };

        let formData = new FormData();
        formData.append("logUid", logUid);
        formData.append("rating", rating.toString());

        xmlhttp.open("POST", self.ratingUrl, true);
        xmlhttp.send(formData);
    }

    toggleSubmitLoading(isLoading: boolean) {
        if (isLoading) {
            let spinner = document.createElement("span");
            spinner.classList.add("manta-spinner-grow");
            spinner.classList.add("manta-spinner-grow-sm");
            spinner.role = "status";
            spinner.ariaHidden = "true";
            if (this.chatSendBtn != null) {
                this.chatSendBtn.innerHTML = "";
                this.chatSendBtn.appendChild(spinner);
                this.chatSendBtn.disabled = true;
            }
            if (this.chatPrompt != null) {
                this.chatPrompt.readOnly = true;
            }
        }
        else {
            let icon = document.createElement("i");
            icon.classList.add("bx");
            icon.classList.add("bx-send");
            if (this.chatSendBtn != null) {
                this.chatSendBtn.innerHTML = "";
                this.chatSendBtn.appendChild(icon);
                this.chatSendBtn.disabled = false;
            }
            if (this.chatPrompt != null) {
                this.chatPrompt.readOnly = false;
                this.chatPrompt.value = "";
            }
        }
    }

    scrollSuggestionsLeft() {
        let scrollAmount = this.suggestedPrompts?.getBoundingClientRect().width ?? 0;
        if (this.suggestedPrompts != undefined) {
            this.suggestedPrompts.scrollLeft -= scrollAmount;
        }
    }

    scrollSuggestionsRight() {
        let scrollAmount = this.suggestedPrompts?.getBoundingClientRect().width ?? 0;
        if (this.suggestedPrompts != undefined) {
            this.suggestedPrompts.scrollLeft += scrollAmount;
        }
    }

    addSuggestedPrompt(text: string) {
        let prompt = document.createElement("div");
        prompt.classList.add("manta-chat-suggested-prompt-item");
        prompt.classList.add("manta-d-block");
        prompt.classList.add("manta-col-auto");
        prompt.classList.add("manta-badge");
        prompt.classList.add("manta-bg-faded-info");
        prompt.classList.add("manta-text-primary");
        prompt.classList.add("manta-fs-xs");
        prompt.classList.add("manta-mb-2");
        prompt.classList.add("manta-px-3");
        prompt.classList.add("manta-py-2");
        prompt.classList.add("manta-cursor-pointer");
        prompt.style.minWidth = "100%";
        prompt.style.maxWidth = "100%";
        prompt.textContent = text;
        this.suggestedPrompts?.appendChild(prompt);

        prompt.addEventListener("click", (e) => {
            if (this.chatPrompt != null) {
                this.chatPrompt.value = text;
                this.chatSendBtn?.click();
            }
        });
    }

    addUserItemToChat(text: string) {
        let userItem = document.createElement("div");
        userItem.classList.add("manta-user-submitted-item"); 
        userItem.classList.add("manta-d-flex");
        userItem.classList.add("manta-align-items-start");
        userItem.classList.add("manta-justify-content-end");
        userItem.classList.add("manta-mb-3");
        userItem.classList.add("manta-col-sm-12");

        let inner = document.createElement("div");
        inner.classList.add("manta-pe-2");
        inner.classList.add("manta-me-1");

        let textWrapper = document.createElement("div");
        textWrapper.classList.add("manta-bg-primary");
        textWrapper.classList.add("manta-text-light");
        textWrapper.classList.add("manta-p-3");
        textWrapper.classList.add("manta-mb-1");
        textWrapper.textContent = text;
        textWrapper.style.borderTopLeftRadius = ".5rem";
        textWrapper.style.borderBottomRightRadius = ".5rem";
        textWrapper.style.borderBottomLeftRadius = ".5rem";
        inner.appendChild(textWrapper);

        let iconCircle = document.createElement("div");
        iconCircle.classList.add("manta-rounded-circle");
        iconCircle.classList.add("manta-bg-secondary");
        iconCircle.classList.add("manta-px-2");
        iconCircle.classList.add("manta-mb-1");
        iconCircle.style.height = "3rem";
        iconCircle.style.width = "3rem";
        iconCircle.style.display = "flex";
        iconCircle.style.justifyContent = "center";
        iconCircle.style.alignItems = "center";

        let icon = document.createElement("i");
        icon.classList.add("bx");
        icon.classList.add("bx-user");
        icon.classList.add("manta-fs-3");
        icon.classList.add("manta-opacity-60");
        iconCircle.appendChild(icon);

        userItem.appendChild(inner);
        userItem.appendChild(iconCircle);

        this.chatHistoryInner?.appendChild(userItem);
    }

    addResponseItemToChat(text: string, allowRating: boolean, logUid: string) {
        let self = this;

        let chatItem = document.createElement("div");
        chatItem.classList.add("manta-chat-response-item");
        chatItem.classList.add("manta-in-frame");
        chatItem.classList.add("manta-d-flex");
        chatItem.classList.add("manta-align-items-start");
        chatItem.classList.add("manta-mb-3");
        chatItem.classList.add("manta-col-sm-12");

        let iconCircle = document.createElement("div");
        iconCircle.classList.add("manta-rounded-circle");
        iconCircle.classList.add("manta-bg-secondary");
        iconCircle.classList.add("manta-px-2");
        iconCircle.classList.add("manta-py-1");
        iconCircle.style.height = "3rem";
        iconCircle.style.width = "3rem";
        iconCircle.style.display = "flex";
        iconCircle.style.justifyContent = "center";
        iconCircle.style.alignItems = "center";

        let icon = document.createElement("i");
        icon.classList.add("bx");
        icon.classList.add("bx-bot");
        icon.classList.add("manta-fs-3");
        icon.classList.add("manta-opacity-60");

        let inner1 = document.createElement("div");
        inner1.classList.add("manta-chat-response-bubble");
        inner1.classList.add("manta-ps-2");
        inner1.classList.add("manta-ms-1");
        inner1.style.maxWidth = "348px";

        let inner2 = document.createElement("div");
        inner2.classList.add("manta-chat-response-item-inner");
        inner2.classList.add("manta-bg-secondary");
        inner2.classList.add("manta-p-3");
        inner2.classList.add("manta-mb-1");
        inner2.style.borderTopRightRadius = ".5rem";
        inner2.style.borderBottomRightRadius = ".5rem";
        inner2.style.borderBottomLeftRadius = ".5rem";

        if (allowRating && logUid) {
            let ratingControl = document.createElement("div");
            ratingControl.classList.add("manta-d-flex");
            ratingControl.classList.add("manta-my-1");
            ratingControl.classList.add("manta-justify-content-end");

            let posRating = document.createElement("button");
            posRating.type = "button";
            posRating.classList.add("manta-log-rating-btn");
            posRating.classList.add("manta-log-rating-positive");
            posRating.classList.add("manta-btn");
            posRating.classList.add("manta-btn-outline-secondary");
            posRating.classList.add("manta-btn-xxs");
            posRating.classList.add("manta-btn-icon");
            posRating.classList.add("manta-rounded-circle");
            posRating.classList.add("manta-me-2");
            posRating.dataset.rating = "1";
            posRating.dataset.logUid = logUid;

            let posIcon = document.createElement("i");
            posIcon.classList.add("bx");
            posIcon.classList.add("bx-like");
            posRating.appendChild(posIcon);

            let negRating = document.createElement("button");
            negRating.type = "button";
            negRating.classList.add("manta-log-rating-btn");
            negRating.classList.add("manta-log-rating-negative");
            negRating.classList.add("manta-btn");
            negRating.classList.add("manta-btn-outline-secondary");
            negRating.classList.add("manta-btn-xxs");
            negRating.classList.add("manta-btn-icon");
            negRating.classList.add("manta-rounded-circle");
            negRating.classList.add("manta-me-2");
            negRating.dataset.rating = "0";
            negRating.dataset.logUid = logUid;

            let negIcon = document.createElement("i");
            negIcon.classList.add("bx");
            negIcon.classList.add("bx-dislike");
            negRating.appendChild(negIcon);

            posRating.addEventListener("click", (e) => {
                self.submitLogRating(posRating, negRating, logUid, 1);
            });

            negRating.addEventListener("click", (e) => {
                self.submitLogRating(posRating, negRating, logUid, 0);
            });

            ratingControl.appendChild(posRating);
            ratingControl.appendChild(negRating);

            inner2.classList.remove("manta-mb-1");

            let textContent = this.getResponseBubbleDiv(text);
            inner2.appendChild(textContent);
            inner1.appendChild(inner2);
            inner1.appendChild(ratingControl);
        }
        else {
            let textContent = this.getResponseBubbleDiv(text);
            inner2.appendChild(textContent);
            inner1.appendChild(inner2);
        }

        iconCircle.appendChild(icon);
        chatItem.appendChild(iconCircle);
        chatItem.appendChild(inner1);

        this.chatHistoryInner?.appendChild(chatItem);
    }

    toggleTyping(isTyping: boolean) {
        if (isTyping) {
            let chatItem = document.createElement("div");
            chatItem.id = "manta-typing";
            chatItem.classList.add("manta-chat-response-item");
            chatItem.classList.add("manta-d-flex");
            chatItem.classList.add("manta-align-items-start");
            chatItem.classList.add("manta-mb-3");

            let iconCircle = document.createElement("div");
            iconCircle.classList.add("manta-rounded-circle");
            iconCircle.classList.add("manta-bg-secondary");
            iconCircle.classList.add("manta-px-2");
            iconCircle.classList.add("manta-py-1");
            iconCircle.style.height = "3rem";
            iconCircle.style.width = "3rem";
            iconCircle.style.display = "flex";
            iconCircle.style.justifyContent = "center";
            iconCircle.style.alignItems = "center";

            let icon = document.createElement("i");
            icon.classList.add("bx");
            icon.classList.add("bx-bot");
            icon.classList.add("manta-fs-3");
            icon.classList.add("manta-opacity-60");

            let inner1 = document.createElement("div");
            inner1.classList.add("manta-ps-2");
            inner1.classList.add("manta-ms-1");
            inner1.style.maxWidth = "348px";

            let inner2 = document.createElement("div");
            inner2.classList.add("manta-chat-response-item-inner");
            inner2.classList.add("manta-bg-secondary");
            inner2.classList.add("manta-p-3");
            inner2.classList.add("manta-mb-1");
            inner2.style.borderTopRightRadius = ".5rem";
            inner2.style.borderBottomRightRadius = ".5rem";
            inner2.style.borderBottomLeftRadius = ".5rem";

            let typing = document.createElement("div");
            typing.classList.add("manta-typing");
            typing.classList.add("manta-mx-2");

            let dot = document.createElement("i");
            dot.classList.add("manta-dot");
            dot.classList.add("bx");
            dot.classList.add("bxs-circle");
            dot.classList.add("manta-fs-xs");
            dot.classList.add("manta-me-1");

            typing.appendChild(dot);
            typing.appendChild(dot.cloneNode(true));
            typing.appendChild(dot.cloneNode(true));
            inner2.appendChild(typing);
            inner1.appendChild(inner2);
            iconCircle.appendChild(icon);
            chatItem.appendChild(iconCircle);
            chatItem.appendChild(inner1);
            this.chatHistoryInner?.appendChild(chatItem);
        }
        else {
            document.getElementById("manta-typing")?.remove();
        }
    }

    wireSuggestionScrolling() {
        const slider = this.suggestedPrompts;
        let isDown = false;
        let startX;
        let scrollLeft;

        if (slider != null) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    }

    getResponseBubbleDiv(text: string) {
        let index = text.indexOf("~~");
        let parentDiv = document.createElement("div");
        if (text.indexOf("~~") >= 0) {
            let starterTextDiv = document.createElement("div");
            starterTextDiv.innerText = text.substring(0, index);
            parentDiv.appendChild(starterTextDiv);

            let items: string[] = [];
            let match = text.match(/~~(.*?)~~/g);
            if (match != null) {
                items = match.map(function (match) {
                    return match.slice(2, -2); // Remove ~~ from the matched string
                });
            }
            if (items.length > 0) {
                let divs = items.map(function (item) {
                    let div = document.createElement("div");
                    div.textContent = item;
                    return div;
                });

                for (let i = 0; i < divs.length; ++i) {
                    parentDiv.appendChild(divs[i]);
                }

            }
        }
        else {
            parentDiv.innerText = text;
        }
        return parentDiv;
    }
}

class MantaLoader {
    colorValue: string;
    loaderTeamplateSVG: string;
    loaderObject: HTMLDivElement;

    constructor(colorValue: string) {
        this.colorValue = colorValue;
        this.loaderTeamplateSVG = this.setTemplateSVG(colorValue);
        this.loaderObject = this.buildLoader();
    }

    setTemplateSVG(colorValue: string) {
        let svg = `<svg xmlns="http://www.w3.org/2000/svg" id="manta-loader-svg" class="manta-loader-rotating" style="width: 3rem;" viewBox="0 0 114.24 134.65">
    <defs>
        <style>.manta-svg-str{stroke-width:0px;}</style>
    </defs>
    <g id="manta-loader-g-1">
        <path id="manta-shell-1" class="manta-svg-str" style="fill: ` + colorValue + `;" d="m64.81,53.04c-1.9-16.69,3.52-33.72,13.64-47.72,1.28-1.77.29-4.31-1.88-4.62-12.02-1.75-24.45-.21-35.72,4.38-1.3.53-4.12,2.12-6.92,3.74-4.27,2.48-6.08,7.7-4.23,12.28,4.81,11.95,14.82,33.7,25.84,38.99,2.31,1.11,5.09.62,7-1.1h0c1.68-1.5,2.52-3.72,2.26-5.96h0Z" />
    </g>
    <g id="manta-loader-g-2">
        <path id="manta-shell-2" class="manta-svg-str" style="fill: ` + colorValue + `;" d="m58.74,68.82c-.12-1.25-.91-2.32-2.05-2.85-16.96-7.88-29.27-23.48-35.88-40.93-.89-2.34-3.88-3.11-5.68-1.36C7.1,31.49,3.08,42.55,1.07,51.64c-1.98,8.99,3.91,17.81,12.95,19.53,12.89,2.45,31.14,4.8,42.26,1.41,1.59-.49,2.63-2.02,2.47-3.68v-.08h0Z" />
    </g>
    <g id="manta-loader-g-3">
        <path id="manta-shell-3" class="manta-svg-str" style="fill: ` + colorValue + `;" d="m62.85,76.92c.09.07.17.14.26.21,1.52,1.19,2.06,3.27,1.24,5.02-5.33,11.37-14.11,20.69-25,27.47-.04.03-.08.05-.13.08-.07.05-.13.09-.21.13-.12.07-.25.15-.38.22-.04.03-.08.05-.12.08-.03.02-.04.02-.03.01-3.26,2.32-11.09,6.95-14.98,7.06-4.46.13-6.02-2.66-9.28-6.63C7.04,101.82,2.15,91.32.06,80.22c-.52-2.77,2.18-5.06,4.83-4.1,17.27,6.29,36.77,7.23,53.65.07,1.46-.62,3.11-.28,4.33.73h-.02Z" />
    </g>
    <g id="manta-loader-g-4">
        <path id="manta-shell-4" class="manta-svg-str" style="fill: ` + colorValue + `;" d="m83.35,100.33s.01.08.01.12c-.03-.61-.16-3.22-.23-4.25.03.6.09,1.87.21,4.12-.35-6.3-2-12.4-4.52-18.15-1.02-2.32-3.57-3.57-6.04-3.04l-.09.02c-1.79.38-3.26,1.67-3.87,3.41-5.87,16.98-19.15,30.74-34.9,39.58-1.9,1.06-1.96,3.8-.06,4.85,5.89,3.25,12.18,6.14,18.84,7.01,20.08,2.62,25.68-3.12,27.81-9.11.92-2.6,1.73-5.14,2.01-6.81.7-4.22,1.05-8.46,1.01-12.75-.01-1.11-.19-4.74-.19-4.99h.01Z" />
    </g>
    <g id="manta-loader-g-5">
        <path id="manta-shell-5" class="manta-svg-str" style="fill: ` + colorValue + `;" d="m109.44,81.34l-.11-.11c-1.06-.87-1.6-1.31-1.81-1.48.05.05.12.12.2.19-6.21-4.85-12.82-8.41-19.97-10.73-2.82-.92-5.8.63-6.79,3.42-.01.03-.02.06-.03.09-.55,1.52-.45,3.2.37,4.59,7.54,12.92,10.22,28.39,8.75,43.36-.17,1.71,1.8,2.75,3.12,1.66,6.54-5.39,11.88-12.23,15.52-19.87,1.89-3.98,3.75-8.54,4.35-12.93.69-4.87.03-4.87-3.6-8.19h0Z" />
    </g>
    <g id="manta-loader-g-6">
        <path id="manta-shell-6" class="manta-svg-str" style="fill: ` + colorValue + `;" d="m103.22,45.35c-4.92-1.53-14.45,4.04-20.56,8.14-2.07,1.39-2.8,4-1.94,6.34.05.12.09.25.13.38.58,1.87,2.21,3.21,4.14,3.53,10.6,1.75,20.82,6.21,29.25,12.78-.15-7.28-2.93-28.67-11.03-31.17h.01Z" />
    </g>
</svg>`;
        return svg;
    }

    buildLoader() {
        let div = document.createElement("div");
        div.id = "manta-frame-loader";
        div.className = "manta-frame-loader";

        let inner = document.createElement("div");
        inner.className = "manta-frame-loader-inner";

        inner.innerHTML = this.loaderTeamplateSVG;
        div.appendChild(inner);

        return div;
    }
}

if (document.readyState !== 'loading') {
    let script = document.querySelector('script[data-id][data-name="MantaAssistant"]') ?? new Element;
    let id = script.getAttribute('data-id') ?? "";
    let isDev = (script.getAttribute('data-is-dev') ?? "") == "true";
    let ea = new ExternalAssistant(id, isDev);
    ea.init();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        let script = document.querySelector('script[data-id][data-name="MantaAssistant"]') ?? new Element;
        let id = script.getAttribute('data-id') ?? "";
        let isDev = (script.getAttribute('data-is-dev') ?? "") == "true";
        let ea = new ExternalAssistant(id, isDev);
        ea.init();
    });
}
